<template>
  <div>
    <UContainer
      role="navigation"
      class="fixed bottom-0 left-1/2 flex w-full -translate-x-1/2 items-center justify-between bg-gradient-to-b from-transparent to-gray-100 pt-4 backdrop-blur-[1px] pb-safe-or-4 sm:py-0 lg:py-safe-or-8"
    >
      <UButton
        icon="i-heroicons-home"
        to="/"
        replace
        size="xl"
        :ui="{ inline: 'flex-col' }"
        square
        :class="[route.path === '/' ? 'bg-upfan-600' : 'bg-gray-300 text-black']"
      />
      <UButton
        icon="i-heroicons-ticket"
        to="/tickets"
        replace
        size="xl"
        :ui="{ inline: 'flex-col' }"
        class="aspect-square"
        square
        :class="[route.path === '/tickets' ? 'bg-upfan-600' : 'bg-gray-300 text-black']"
      />
      <UButton
        icon="i-heroicons-user"
        to="/profile"
        replace
        size="xl"
        :ui="{ inline: 'flex-col' }"
        class="aspect-square"
        square
        :class="[(route.path.startsWith('/profile') || route.path.startsWith('/auth')) && route.path !== '/profile/tickets' ? 'bg-upfan-600' : 'bg-gray-300 text-black']"
      />
    </UContainer>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
</script>
